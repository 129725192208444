



















import Vue from 'vue'
export default Vue.extend({
  props: [
    'data',
  ],
  computed: {
    jsonContent(): string {
      if (typeof this.data === 'object')
        return JSON.stringify(this.data, null, 2).trim();

      return this.data;
    }
  }
})
