//@ts-nocheck
import Alignment from './alignment.yaml';
import Annotation from './annotation.yaml';
import Base from './base.yaml';
import Classification from './classification.yaml';
import DataBud_Rendering from './databud-rendering.yaml';
import Encoding from './encoding.yaml';
import Form from './form.yaml';
import Format from './format.yaml';
import Transformation_Handlebars from './transformation.handlebars.yaml';
import Transformation_Jolt from './transformation.jolt.yaml';
import Transformation_Jq from './transformation.jq.yaml';
import Transformation_SenML from './transformation.senml.yaml';
import Validation from './validation.yaml';

export const all = {
  Alignment,
  Annotation,
  Base,
  Classification,
  DataBud_Rendering,
  Encoding,
  Form,
  Format,
  Transformation_Handlebars,
  Transformation_Jolt,
  Transformation_Jq,
  Transformation_SenML,
  Validation,
};