












































import Vue from "vue";
import { setInstance as setVaultifier, initialize as initializeSoya, soya } from './services';
import Spinner from './components/Spinner.vue'
import NavBar from './components/NavBar.vue'
import Login, { Data as LoginData } from './components/Login.vue'
import { Vaultifier, VaultEncryptionSupport, VaultSupport, VaultInfo, VaultifierWeb, OAuthIdentityProvider, OAuthSupport, OAuthExternalProvider } from 'vaultifier';
import { RoutePath } from './router';
import { RouteParams } from "./router/routes";
import { IStore } from "./store";
import { ConfigService } from "./services/config-service";
import { RepoService, Soya } from "soya-js";

const isLoginData = (data: any): data is LoginData => {
  const d = data as LoginData;
  return d.appKey !== undefined && d.appSecret !== undefined;
}

interface IData {
  isInitializing: boolean,
  isLoggedIn: boolean,
  message?: string,
  encryptionSupport?: VaultEncryptionSupport,
  vaultSupport?: VaultSupport,
  vaultInfo?: VaultInfo,
  vaultUrl?: string,
}

export default Vue.extend({
  components: {
    Spinner,
    Login,
    NavBar,
  },
  created() {
    this.initialize();
  },
  data: (): IData => ({
    isInitializing: true,
    isLoggedIn: false,
    message: undefined,
    encryptionSupport: undefined,
    vaultSupport: undefined,
    vaultInfo: undefined,
    vaultUrl: undefined,
  }),
  methods: {
    async initialize() {
      this.tryInitializeVaultifier();

      const { searchParams } = new URL(window.location.href);

      const schema = searchParams.get(RouteParams.SCHEMA_DRI);
      if (schema && this.$router.currentRoute.path !== RoutePath.SCHEMA_VIEW)
        this.$router.push(RoutePath.SCHEMA_VIEW);

      const itemId = searchParams.get(RouteParams.ITEM_ID);
      if (itemId && this.$router.currentRoute.path !== RoutePath.ITEM_VIEW)
        this.$router.push(RoutePath.ITEM_VIEW);
    },
    async tryInitializeVaultifier(credentials?: OAuthIdentityProvider | OAuthExternalProvider | LoginData) {
      this.isInitializing = true;

      // Here we removed quite substantial amount of code from the original data bud
      // as we currently don't have VaultifierWeb configuration for SOyA

      const { searchParams } = new URL(window.location.href);
      let endpointUrl = searchParams.get('PIA_URL')?.trim();
      if (!endpointUrl) {
        endpointUrl = `${window.location.protocol}//${window.location.host}`;
      }
      initializeSoya(new Soya({ service: new RepoService(endpointUrl) }));

      // TODO: This is a quirky type conversion, but since vaultifier version 3.x supports the new generation of SemCons
      // SOyA and new SemCons are currently not compatible (and thus soya-js still builds upon vaultifier version 2.x)
      // However, for the use in this project the differences should not be noticable, thus the conversion is okayish
      let vaultifier: Vaultifier = await soya.service.getVaultifier() as unknown as Vaultifier;
      setVaultifier(vaultifier);

      if (!vaultifier) {
        this.message = `Sorry. I was not able to create a vaultifier instance.
Try looking into the browser console to gain more insights on the problem.`;
        this.isInitializing = false;
        return;
      }

      try {
        this.vaultSupport = await vaultifier.getVaultSupport();

        if (await vaultifier.isAuthenticated()) {
          this.isLoggedIn = true;
        }
        else {
          try {
            await vaultifier.initialize();
            this.isLoggedIn = await vaultifier.isAuthenticated();
          } catch { /* vaultifier throws an error if no credentials can be determined */ }
        }

        this.encryptionSupport = await vaultifier.setEnd2EndEncryption(true);

        if (this.isLoggedIn) {
          this.vaultInfo = await vaultifier.getVaultInfo();
        }
      }
      catch {
        if (vaultifier.urls.baseUrl)
          this.message = `I'm not sure ${vaultifier.urls.baseUrl} is the correct endpoint I should connect to. Please check this again.`;
        else
          this.message = `I could not find any endpoint to connect to.`
      }

      this.isInitializing = false;
    },
    logIn(credentials: LoginData) {
      this.tryInitializeVaultifier(credentials);
    },
    logOut() {
      this.isLoggedIn = false;
      VaultifierWeb.clearAuthentication();
    },
  },
  computed: {
    hasMessage(): boolean {
      return !!this.message;
    },
    isLoginFormShowed(): boolean {
      return !this.isInitializing && !this.isLoggedIn;
    },
    title(): string {
      return this.vaultInfo?.name || 'OYD-DataBud';
    },
    description(): string | undefined {
      return this.vaultInfo?.description;
    },
    state(): IStore {
      return this.$store.state as IStore;
    },
    isUiFluid(): boolean {
      return this.state.ui.isFluid;
    },
    identityProviders(): (OAuthSupport | OAuthIdentityProvider | OAuthExternalProvider)[] | undefined {
      return this.vaultSupport?.oAuth;
    },
    hasLogout(): boolean {
      return this.vaultSupport?.authentication === true && this.isLoggedIn;
    }
  },
  watch: {
    title() {
      document.title = this.title;
    }
  }
});
